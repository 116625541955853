<template>
  <div>
    <slot name="button" :openModal="openModal"> </slot>
    <BaseModal
      :name="`contract-update-${id}`"
      size="md"
      :title="$t('seller.contracts.text_2464')"
    >
      <b-container>
        <b-row>
          <b-col cols="12">
            <b-form-group :label="$t('seller.contracts.text_2465')" label-for="amount">
              <b-form-input
                id="amount"
                v-model="amount"
                type="text"
                name="amount"
                v-validate="'required'"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('amount')">
                {{ $t('seller.contracts.text_2466') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>

      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" class="mr-4" @click="cancel">
          {{ $t('seller.contracts.text_2467')  }}
        </BaseButton>
        <BaseButton variant="primary" @click="onSubmit"> {{ $t('seller.contracts.text_2468') }} </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>
<script>
import ContractService from "@/services/resources/ContractService";
import Paginate from "@/components/shared/Paginate";
import { EventBus } from "@/main.js";

const service = ContractService.build();

export default {
  components: {
    Paginate,
  },
  data() {
    return {
      loading: true,
      contract_id: -1,
      amount: null,
      id: parseInt(Math.random() * 1000),
    };
  },
  watch: {
    search(newValue) {
      this.fetchUsers(newValue);
    },
  },
  methods: {
    openModal(contract_id) {
      this.contract_id = contract_id;
      this.$bvModal.show(`contract-update-${this.id}`);
      this.fetchUsers();
    },
    select(id, index) {
      this.user_id = id;
      this.active = index;
    },
    onSubmit() {
      this.loading = true;

      let data = {
        id: this.contract_id,
        amount: parseFloat(this.amount.replace(",", ".")),
      };

      service
        .update(data)
        .then((response) => {
          if (response.success) {
            this.$bvToast.toast(this.$t('seller.contracts.text_2469'), {
              title: this.$t('seller.contracts.text_2471'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            EventBus.$emit('relist-contracts');
            this.$emit("close");
          } else if (!response.success) {
            this.$bvToast.toast(this.$t('seller.contracts.text_2470'), {
              title: this.$t('seller.contracts.text_2471'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }

          this.$bvModal.hide(`contract-update-${this.id}`);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Affiliate-item {
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    background: #eee;
    color: #111;
  }
}

.active {
  background: $base-color;
  color: #fff;
}
</style>